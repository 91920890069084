export const ShorebirdLogo = ({ className }: { className?: string }) => (
  <svg
    width="32"
    height="27"
    viewBox="0 0 32 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className ?? 'h-10 w-10'}
  >
    <path
      d="M5.14266 7.36943C6.46801 1.61099 12.2836 0.0571273 15.0257 0L13.5975 1.8852C16.3396 1.94233 18.2248 4.7987 19.3102 6.79815C20.11 8.74048 25.0229 12.7965 27.0224 13.5963C29.0219 14.3961 30.7357 13.2535 31.4783 13.025C32.221 12.7965 31.9354 13.2535 31.9925 13.5963C32.0496 13.9391 31.0213 15.8243 29.6503 17.7095C28.2792 19.5947 24.6802 21.4227 23.5376 21.9369C22.3951 22.451 20.0529 22.8509 16.7395 22.7367C13.4261 22.6224 10.3412 20.7372 8.79881 19.5375C7.25637 18.3379 3.48596 14.5675 5.14266 7.36943Z"
      fill="url(#paint0_radial_532_20)"
    />
    <path
      d="M15.4808 7.31245C16.7604 5.89569 18.5656 6.37937 19.3083 6.7983C19.632 7.12202 20.3823 7.91799 20.7936 8.51212C21.3078 9.25477 23.8214 11.0828 24.8497 11.5399C25.878 11.9969 26.9063 11.9398 27.4775 12.054C28.0488 12.1683 27.9917 12.3397 27.8774 12.8538C27.7632 13.3679 24.7354 15.0818 23.8214 15.5959C22.9074 16.1101 21.6506 16.9132 18.9084 16.5099C16.9661 16.2243 14.681 13.5965 14.1097 12.3397C13.5384 11.0828 13.8812 9.0834 15.4808 7.31245Z"
      fill="url(#paint1_linear_532_20)"
    />
    <path
      d="M0 9.71136L5.14146 7.42627C5.00435 7.92899 4.93199 8.54977 4.91295 8.79733L0 9.71136Z"
      fill="#897F76"
    />
    <circle cx="4.57031" cy="8.05468" r="0.114255" fill="#070707" />
    <ellipse cx="9.02535" cy="7.42598" rx="1.48531" ry="1.77095" fill="black" />
    <circle cx="9.02538" cy="6.68358" r="0.457019" fill="white" />
    <path
      d="M12.7978 25.2502H15.5971L16.8539 22.8508L17.9393 22.7937L16.7968 25.2502H17.9964C18.2821 25.2502 18.3963 25.5929 18.3963 25.8214C18.3963 26.0043 18.0916 26.2023 17.9393 26.2785H12.7978C12.5693 26.2785 12.5693 25.8786 12.5693 25.7072C12.5693 25.5701 12.7217 25.3454 12.7978 25.2502Z"
      fill="#AA937E"
    />
    <path
      d="M14.7402 25.3071H17.5395L18.7963 22.9077L19.8817 22.8506L18.7391 25.3071H19.9388C20.2245 25.3071 20.3387 25.6498 20.3387 25.8783C20.3387 26.0611 20.034 26.2592 19.8817 26.3354H14.7402C14.5117 26.3354 14.5117 25.9355 14.5117 25.7641C14.5117 25.627 14.6641 25.4023 14.7402 25.3071Z"
      fill="#B19A84"
    />
    <defs>
      <radialGradient
        id="paint0_radial_532_20"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.5759 7.60491) rotate(131.66) scale(18.2956 32.4674)"
      >
        <stop offset="0.0263478" stopColor="#897F75" />
        <stop offset="0.538541" stopColor="#DEDAD4" />
        <stop offset="0.86124" stopColor="#F2F4F3" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_532_20"
        x1="21.8791"
        y1="9.65467"
        x2="17.0804"
        y2="15.3674"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#AE9883" />
        <stop offset="1" stopColor="#A9927D" />
      </linearGradient>
    </defs>
  </svg>
);
